<template>
  <van-collapse v-model="activeNames">
    <van-collapse-item :name="1" :border="false">
      <template #title>
        <div class="title" @click.stop="onClick">
          <span class="icon-svg">
            <img
              v-if="list && result.length == list.length"
              src="./check-square-fill.svg"
            />
            <img v-else-if="result.length" src="./minus-square-fill.svg" />
            <img v-else src="./square.svg" />
          </span>
          <span>{{ name }}</span>
        </div>
      </template>
      <van-checkbox-group v-model="result" style="padding-left: 16px">
        <van-checkbox
          v-for="(item, index) in list"
          :key="item.id"
          :style="{ 'margin-top': index ? '8px' : '' }"
          :name="`${id}_${item.id}_${item.name}`"
        >
          <template #icon="props">
            <img v-if="props.checked" src="./check-square-fill.svg" />
            <img v-else src="./square.svg" />
          </template>
          {{ item.name }}
        </van-checkbox>
      </van-checkbox-group>
    </van-collapse-item>
  </van-collapse>
</template>
<script>
export default {
  props: ['list', 'name', 'id'],
  data() {
    return {
      activeNames: [],
      result: [],
    }
  },
  computed: {
    ids() {
      let index = 0
      const arr = []
      while (index < this.list.length) {
        const item = this.list[index]
        arr.push(`${this.id}_${item.id}_${item.name}`)
        index++
      }
      return arr
    },
  },
  methods: {
    onClick() {
      if (this.result.length && this.result.length == this.list.length) {
        this.result = []
      } else {
        this.result = JSON.parse(JSON.stringify(this.ids))
      }
    },
  },
}
</script>
<style lang='less' scoped>
/deep/ .van-collapse-item__content {
  padding-top: 0;
}
.title {
  display: inline-flex;
  align-items: center;
  .icon-svg {
    display: flex;
    align-items: center;
    padding-right: 8px;
  }
}
</style>