<template>
  <div class="goal-progress-page">
    <div class="content-wrap">
      <van-cell title="核心业务转化率" :border="false">
        <template #icon>
          <van-icon
            :name="require('./mbjdtj.svg')"
            style="display: flex;align-items: center;"
          />
        </template>
      </van-cell>
      <!-- <van-cell title="核心业务转化率" :border="false" /> -->
      <div class="options">
        <!-- <div class="item" @click="actionSheetShow = true">
          <span>渠道：{{ actions[sheetIndex].name }}</span>
          <van-icon class="play" name="play" />
        </div> -->
        <div class="item time">
          <div
            class="week"
            :class="{ active: dataText == 'week' }"
            @click="onDateShortcut('week')"
          >
            本周
          </div>
          <div
            :class="{ active: dataText == 'month' }"
            class="month"
            @click="onDateShortcut('month')"
          >
            本月
          </div>
        </div>

        <!-- <div class="item" @click="onDateSelect">
          <span>自定义</span>
          <span v-if="dataText">：{{ dataText }}</span>
          <van-icon class="play" name="play" />
        </div> -->
        <!-- 销售顾问 或 店长 -->
        <div
          v-if="roleLogos.includes('1014003') || roleLogos.includes('1014004')"
          class="item role"
          @click="popupShow = true"
        >
          <span>员工：</span>
          <span v-if="!empData.length">全部</span>
          <van-tag v-else closeable @close="deleteEmp">
            {{ empData[empData.length - 1].name }}
          </van-tag>
          <van-tag v-show="empData.length > 1" style="margin-left: 5px">
            {{ `+${empData.length - 1}` }}
          </van-tag>
          <van-icon class="play" name="play" />
        </div>
      </div>
      <div style="min-height: 215px">
        <Charts v-if="userInfo.id" ref="charts" :formData="formData"  style="padding: 5px 10px 10px"/>
      </div>
    </div>
    <div class="content-wrap" style="margin-top:10px">
      <van-cell title="本月目标进度统计" :border="false">
        <template #icon>
          <van-icon
            :name="require('./mbjdtj.svg')"
            style="display: flex;align-items: center;"
          />
        </template>
      </van-cell>
      <div v-if="kpi.isSetTarget" class="kpi-content">
        <div
          v-for="i in labelCount"
          :key="i"
          class="item"
          :class="{ percentage: i == 2 }"
        >
          <div
            v-if="i == 2 && kpi.targetStats[0]"
            class="time-line"
            :style="{ left: kpi.targetStats[0].timeRate }"
          ></div>
          <div style="line-height: 30px; height: 30px">
            {{ ['', '时间进度', '完成率', '目标差距'][i - 1] }}
            {{
              i == 2 && kpi.targetStats[0]
                ? `：${kpi.targetStats[0].timeRate}`
                : ''
            }}
          </div>
          <div
            v-for="(item, index) in kpi.targetStats.filter(({ code })=>!['PASSENGER_TARGET', 'FOLLOW_TARGET'].includes(code))"
            :key="index"
            class="data"
          >
            <div v-if="i == 1">
              <p style="line-height: 1">{{ item.name }}</p>
              <p style="line-height: 1">
                {{ `(${item.finished}/${item.target})` }}
              </p>
            </div>
            <div v-else-if="i == 2">
              <van-progress
                :percentage="item.finishRate | percentageF"
                :show-pivot="false"
                color="#07c160"
              />
            </div>
            <div v-else-if="i == 3">
              {{ `${item.finishRate}` }}
            </div>
            <div v-else-if="i == 4 && item.difference" style="color: red">
              {{ item.difference }}
            </div>
          </div>
        </div>
      </div>
      <van-empty
        v-else
        :description="
          roleLogos.includes('1014003')
            ? '当前未设置业绩目标，请前往店端Portal设置'
            : '当前暂未设置业绩目标'
        "
      />
    </div>
    <div class="content-wrap" style="margin-top:10px">
      <van-cell title="业绩排行" :border="false">
        <template #icon>
          <van-icon
            :name="require('./mbjdtj.svg')"
            style="display: flex;align-items: center;"
          />
        </template>
      </van-cell>
      <img src="./Performance_Rankings.png" width="100%" />
    </div>
    <van-calendar
      ref="calendar"
      v-model="calendarShow"
      type="range"
      :first-day-of-week="1"
      title="时间选择"
      allow-same-day
      :show-confirm="false"
      :min-date="new Date($moment().subtract(10, 'year'))"
      :max-date="new Date()"
      :default-date="[]"
      @confirm="onConfirm"
    >
      <template v-if="false" #footer>
        <div class="calendar-footer">
          <div class="btn-common" @click="onDateShortcut('week')">本周</div>
          <div class="center"></div>
          <div class="btn-common" @click="onDateShortcut('month')">本月</div>
        </div>
      </template>
    </van-calendar>
    <van-action-sheet
      v-model="actionSheetShow"
      :style="{
        left: '50%',
        transform: 'translateX(-50%)',
        'max-width': '500px',
      }"
      :actions="actions"
      cancel-text="取消"
      close-on-click-action
      @select="onChannelSelect"
    />
    <van-popup
      v-model="popupShow"
      position="bottom"
      class="safe-area-inset-bottom"
      :style="{
        height: 'auto',
        'max-height': '75%',
        'min-height': '50%',
        'border-radius': '16px 16px 0 0',
        left: '50%',
        transform: 'translateX(-50%)',
        'max-width': '500px',
      }"
      @close="onClosePopup"
    >
      <div class="popup-title">
        <p @click="popupShow = false">确认</p>
      </div>
      <Select
        v-for="item in items"
        :id="item.id"
        :key="item.id"
        :ref="`select_${item.id}`"
        :list="item.buddys"
        :name="item.name"
      />
    </van-popup>
  </div>
</template>
<script>
import { listSalesGroup, statisticsKpi } from '@/services/goalProgress'
import Charts from './components/Charts'
import Select from './components/Select'
import { mapGetters } from 'vuex'
import Big from 'big.js'
export default {
  components: {
    Charts,
    Select,
  },
  filters: {
    percentageF(percentString) {
      if (!percentString) return 0
      const n = parseFloat(percentString.slice(0, -1))
      return new Big(n).gt(100) ? 100 : n
    },
  },
  data() {
    return {
      labelCount: 3, // 4
      popupShow: false,
      actionSheetShow: false,
      kpi: {
        isSetTarget: false,
        targetStats: [],
      },
      formData: {
        empIds: [],
        online: '',
        statisticsTime: {
          start: null,
          end: null,
        },
        queryType: '',
      },
      sheetIndex: 0,
      items: [],
      actions: [
        {
          value: '',
          name: '全部',
        },
        {
          value: '1',
          name: '线上',
        },
        {
          value: '0',
          name: '线下',
        },
      ],
      calendarShow: false,
      empData: [],
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    roleLogos() {
      return this.userInfo.roleLogos || []
    },
    dataText() {
      const { start, end } = this.formData.statisticsTime
      if (
        this.$moment()
          .isoWeekday(1)
          .isSame(start, 'day') &&
        this.$moment()
          .isoWeekday(7)
          .isSame(end, 'day')
      ) {
        return 'week'
      } else if (
        this.$moment()
          .startOf('month')
          .isSame(start, 'day') &&
        this.$moment()
          .endOf('month')
          .isSame(end, 'day')
      ) {
        return 'month'
      }

      return (
        this.$moment(start).format('YYYY-MM-DD') +
        '~' +
        this.$moment(end).format('YYYY-MM-DD')
      )
    },
    pText() {
      return this.formData.empIds.join() || '全部'
    },
  },
  watch: {
    userInfo: {
      async handler(val) {
        if (val && val.id) {
          this.getKpiData(val?.dealers[0].id)
          const roleLogos = val.roleLogos
          if (roleLogos.includes('1014003') || roleLogos.includes('1014004')) {
            const list = await listSalesGroup({
              dealerId: val?.dealers[0].id || '',
            })
            // this.items = JSON.parse(
            //   JSON.stringify(list)
            //     .replaceAll('name', 'text')
            //     .replaceAll('buddys', 'children')
            // )
            this.items = list
          }
        }
      },
      immediate: true,
    },
  },
  created() {
    this.onDateShortcut('month', false)
  },
  methods: {
    deleteEmp() {
      const { pId, id } = this.empData[this.empData.length - 1]
      const result = this.$refs[`select_${pId}`][0].result
      const index = result.findIndex((e) => id == e)
      this.empData.pop()
      this.$refs[`select_${pId}`][0].result.splice(index, 1)
      this.formData.empIds = this.empData.map(({ id }) => id)
      this.queryTranfer()
    },
    onClosePopup() {
      const arr = []
      const arr2 = []
      this.items.forEach(({ id }) => {
        const result = this.$refs[`select_${id}`][0].result
        result.length &&
          arr.push(
            ...result.map((e) => {
              const [pId, id, name] = e.split('_')
              arr2.push({ pId, id, name })
              return id
            })
          )
      })
      this.empData = arr2
      this.formData.empIds = arr
      this.queryTranfer()
    },
    getKpiData(dealerId) {
      statisticsKpi({ dealerId }).then((res) => {
        this.kpi = res
      })
    },
    onPopupConfirm() {
      this.popupShow = false
    },
    onChannelSelect(action, index) {
      this.sheetIndex = index
      this.queryTranfer()
    },
    queryTranfer() {
      this.formData.online = this.actions[this.sheetIndex].value
      this.$refs.charts.initTranfer(this.formData)
    },
    onDateSelect() {
      this.calendarShow = true
      this.$refs.calendar.scrollToDate(new Date())
    },
    onConfirm(data) {
      this.calendarShow = false
      const start = this.$moment(data[0]).format('YYYY-MM-DD 00:00:00')
      const end = this.$moment(data[1]).format('YYYY-MM-DD 23:59:59')
      this.formData.statisticsTime = {
        start,
        end,
      }
      this.queryTranfer()
    },
    getCurrentWeek() {
      const start = this.$moment()
        .isoWeekday(1)
        .format('YYYY-MM-DD 00:00:00') // 本周一
      const end = this.$moment()
        .isoWeekday(7)
        .format('YYYY-MM-DD 23:59:59') // 本周日
      this.formData.statisticsTime = {
        start,
        end,
      }
      this.formData.queryType = 1
    },

    onDateShortcut(type, load = true) {
      this.calendarShow = false
      if (type == 'week') {
        this.getCurrentWeek()
      } else {
        const start = this.$moment()
          .startOf(type)
          .format('YYYY-MM-DD 00:00:00')
        const end = this.$moment()
          .endOf(type)
          .format('YYYY-MM-DD 23:59:59')
        this.formData.statisticsTime = {
          start,
          end,
        }
        this.formData.queryType = 2
      }
      load && this.queryTranfer()
    },
  },
}
</script>
<style lang="less" scoped>
.popup-title {
  position: sticky;
  display: flex;
  justify-content: flex-end;
  left: 0;
  top: 0;
  z-index: 1;
  background: white;
  p {
    line-height: 36px;
    height: 44px;
    padding: 4px 16px;
    cursor: pointer;
  }
}
.goal-progress-page {
  padding: 10px 0;
  .content-wrap {
    margin: 0 10px;
    border-radius: 8px;
    background: white;
    overflow: hidden;
    /deep/ .van-cell {
      padding: 10px;
    }
  }
  .kpi-content {
    padding: 0 10px 10px;
    display: flex;
    .item {
      font-size: 13px;
      text-align: center;
      &:not(:last-child) {
        margin-right: 5px;
      }
      .data {
        height: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
    .percentage {
      position: relative;
      flex: 1;
      .time-line {
        position: absolute;
        z-index: 1;
        height: calc(100% - 50px);
        bottom: 10px;
        border-left: 1px dashed red;
        left: 0;
      }
    }
  }
}
.options {
  padding: 0 10px;
  display: flex;
  flex-wrap: wrap;
  font-size: 13px;
  .item {
    margin-bottom: 5px;
    cursor: pointer;
    &:not(:last-child) {
      margin-right: 16px;
    }
  }
  .time {
    display: flex;
    > div {
      width: 40px;
      line-height: 20px;
      border-style: solid;
      text-align: center;
      border-color: #0d171a;
      &.active {
        background: #eed484;
        color: white;
        border-color: #eed484;
      }
    }
    .week {
      border-radius: 4px 0 0 4px;
      border-width: 1px 0 1px 1px;
    }
    .month {
      border-radius: 0 4px 4px 0;
      border-width: 1px 1px 1px 0;
    }
  }
  .role {
    display: flex;
    align-items: center;
  }
  .play {
    transform: rotate(90deg);
  }
}
.calendar-footer {
  display: flex;
  text-align: center;
  height: 44px;
  :first-child {
    flex: 1;
    line-height: 44px;
    border-radius: 22px 0 0 22px;
  }
  .center {
    width: 1px;
    background: #fff;
  }
  :last-child {
    flex: 1;
    line-height: 44px;
    border-radius: 0 22px 22px 0;
  }
}
</style>
