import request from '@/utils/request'
import SERVER from './server'
import { Toast } from 'vant'
import { thenResolve } from '@/utils/index'

const orderServices = {
  /**
   * 工作概览
  */
  async workOverview(params) {
    return await request.post(`${SERVER.STATISTICS_SERVICE}/api/v1/statistics/work-overview`, params).then(thenResolve)
  },
  /**
   * 员工完成统计
   */
  async empCount(params) {
    return await request.post(`${SERVER.STATISTICS_SERVICE}/api/v1/statistics/emp-count`, params).then(thenResolve)
  },
  /**
   * 排行榜
   */
  async rankInfo(params) {
    return await request.post(`${SERVER.STATISTICS_SERVICE}/api/v1/statistics/rank`, params)
  },
  /**
   * 代办日历查询
   */
  async todoCalendar(params) {
    return await request.post(`${SERVER.STATISTICS_SERVICE}/api/v1/todo/calendar`, params)
  },
  /**
   * 代办列表
   */
  async todoList(params) {
    return await request.post(`${SERVER.STATISTICS_SERVICE}/api/v1/todo/list`, params)
  },
  /**
   * 获取常用功能列表
   */
  async commonEmpResource(params) {
    return await request.get(`${SERVER.ADMIN_SERVICE}/api/v1/emp-resource/commons`, { params }).then(thenResolve)
  },
  /**
   * 查询所有功能
   */
  async allEmpResource(params) {
    return await request.get(`${SERVER.ADMIN_SERVICE}/api/v1/emp-resource/all`, { params }).then(thenResolve)
  },
  /**
   * 配置常用功能
   */
  async configEmpResource(params) {
    return await request.post(`${SERVER.ADMIN_SERVICE}/api/v1/emp-resource/config`, params)
  },
  /**
   * 应用代办统计
   */
  async appTodo(params) {
    return await request.post(`${SERVER.STATISTICS_SERVICE}/v1/app/stat/app-todo`, params).then(thenResolve)
  },
  /**
   * 获取大区数据
   */
  async getRegionTreeApi(params) {
    return await request.get(`${SERVER.ADMIN_SERVICE}/api/v1/region/regionTree`, { params }).then(thenResolve)
  },
  /**
   * 获取城市区域
   */
  async getRegionPageApi(params) {
    return await request.post(`${SERVER.ADMIN_SERVICE}/api/v1/dealer/page`, params).then(thenResolve)
  },
  /**
   * 获取省市数据（{parentId: 0 省份传0}）
   */
  async getPronceApi(params) {
    return await request.get(`${SERVER.BASE_DATA_SERVICE}/api/v1/area/list`, { params }).then(thenResolve)
  },
}
export default orderServices
